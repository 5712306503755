<template>
  <div class="flow_contanter">
    <div class="flow_text" @click="detailsBtn">详细操作流程查看>></div>
    <div class="userBtn" @click="mineMessage">
      <img src="../../../assets/userPhone1.png" />
      <div>联系客服</div>
    </div>
    <!-- 流程表 -->
    <div class="flow_content">
      <div class="flow_list">
        <div class="flow_header">
          <div class="flow_box" style="color:#FF5E5E;border:1px solid #FF5E5E">1</div>
          <div class="line_flow" style="border:1px dashed #FF5E5E"></div>
        </div>
        <div>
          <div style="display:flex">
            <div class="flow_title" style="color:#FF5E5E">信息提交</div>
            <img class="flow_imgBtn" src="../../../assets/successIcon2.png" />
            <div class="flow_text1" v-if="queryList.status&&tableData.info_state==1" @click="lookBtn">点击填写股份信息>></div>
          </div>
          <div class="flow_text1" v-if="!queryList.status&&tableData.info_state==1" @click="lookBtn">重新填写信息>></div>
        </div>
      </div>
      <div class="flow_list">
        <div class="flow_header">
          <div class="flow_box" :style="tableData.info_state>=1?'color:#FF5E5E;border:1px solid #FF5E5E':''">2</div>
          <div class="line_flow" :style="tableData.info_state>=1?'border:1px dashed #FF5E5E':''"></div>
        </div>
        <div>
          <div style="display:flex">
            <div class="flow_title" :style="tableData.info_state>=1?'color:#FF5E5E':''">平台受理</div>
            <img v-if="tableData.info_state==2" class="flow_imgBtn" src="../../../assets/successIcon2.png" />
            <img v-if="tableData.info_state==3" class="flow_imgBtn" src="../../../assets/failIcon.png" />
          </div>
          <div v-if="tableData.info_state==1" class="flow_text1" style="color:#000">请耐心等候客服联系,请保持电话畅通！</div>
          <div v-if="tableData.info_state==2" class="flow_text1" style="color:#000">平台审核已通过</div>
          <div v-if="tableData.info_state==3">
            <div class="flow_text1">审核终止,申请已拒绝</div>
            <div class="flow_text1">原因:{{tableData.info_remark[0]}}</div>
            <!-- <div class="flow_text1" @click="lookBtn">重新填写信息>></div> -->
          </div>

        </div>
      </div>
      <div class="flow_list" v-if="tableData.audit_state!=3&&tableData.info_state!=3">
        <div class="flow_header">
          <div class="flow_box" :style="tableData.info_state>=2?'color:#FF5E5E;border:1px solid #FF5E5E':''">3</div>
          <div class="line_flow" :style="tableData.info_state>=2?'border:1px dashed #FF5E5E':''"></div>
        </div>
        <div>
          <div style="display:flex">
            <div class="flow_title" :style="tableData.info_state>=2?'color:#FF5E5E':''">合同邮寄</div>
            <img class="flow_imgBtn" v-if="tableData.shipments.length>0" src="../../../assets/successIcon2.png" />
          </div>
          <div class="flow_text1" style="color:#000" v-if="tableData.info_state==2&&tableData.shipments.length==0">等待邮寄，平台整理物料中！</div>
          <div class="flow_text1" @click="logistics1" v-if="tableData.info_state==2&&tableData.shipments.length>0">查看物流></div>
        </div>
      </div>
      <div class="flow_list" v-if="tableData.audit_state!=3&&tableData.info_state!=3">
        <div class="flow_header">
          <div class="flow_box" :style="tableData.shipments.length>0?'color:#FF5E5E;border:1px solid #FF5E5E':''">4</div>
          <div class="line_flow" :style="tableData.shipments.length>0?'border:1px dashed #FF5E5E':''"></div>
        </div>
        <div>
          <div style="display:flex">
            <div class="flow_title" :style="tableData.shipments.length>0?'color:#FF5E5E':''">合同回寄</div>
            <img class="flow_imgBtn" v-if="tableData.shipments.length>1" src="../../../assets/successIcon2.png" />
          </div>
          <div class="flow_text1" style="color:#000" v-if="tableData.shipments.length==1">按要求填写与操作,完成后邮寄回指定地址</div>
          <div class="flow_text1" v-if="tableData.shipments.length==1" @click="lookAddress">查看地址,填写单号</div>
          <div class="flow_text1" v-if="tableData.shipments.length==2" @click="lookAddress">查看详情</div>
        </div>
      </div>
      <div class="flow_list" v-if="tableData.audit_state!=3&&tableData.info_state!=3">
        <div class="flow_header">
          <div class="flow_box" :style="tableData.shipments.length==2?'color:#FF5E5E;border:1px solid #FF5E5E':''">5</div>
          <div class="line_flow" :style="tableData.shipments.length==2?'border:1px dashed #FF5E5E':''"></div>
        </div>
        <div>
          <div style="display:flex">
            <div class="flow_title" :style="tableData.shipments.length==2?'color:#FF5E5E':''">信息确认，物料发放</div>
            <img class="flow_imgBtn" v-if="tableData.audit_state==2" src="../../../assets/successIcon2.png" />
          </div>
          <div class="flow_text1" v-if="tableData.audit_state==1&&tableData.shipments.length==2" style="color:#000">请耐心等待平台最终确认！</div>
        </div>
      </div>
      <div class="flow_list" v-if="tableData.audit_state!=3&&tableData.info_state!=3">
        <div class="flow_header">
          <div class="flow_box" :style="tableData.audit_state==2?'color:#FF5E5E;border:1px solid #FF5E5E':''">6</div>
        </div>
        <div>
          <div style="display:flex">
            <div class="flow_title" :style="tableData.audit_state==2?'color:#FF5E5E':''">已完成</div>
            <img class="flow_imgBtn" v-if="tableData.audit_state==2" src="../../../assets/successIcon2.png" />
          </div>
        </div>
      </div>

      <div class="flow_list" v-if="tableData.audit_state==3||tableData.info_state==3">
        <div class="flow_header">
          <div class="flow_box" style="color:#FF5E5E;border:1px solid #FF5E5E">{{tableData.audit_state==3?'5':(tableData.info_state==3?'3':'')}}</div>
        </div>
        <div>
          <div style="display:flex">
            <div class="flow_title" style="color:#FF5E5E">已结束</div>
          </div>
          <div v-if="tableData.audit_close==1">
            <div class="flow_text1">审核终止,已关闭</div>
            <div class="flow_text1" v-if="tableData.audit_remark">原因:{{tableData.audit_remark?tableData.audit_remark[0]:''}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flow_popbox" v-if="popShow">
      <div class="flow_pop1" v-if="popShow1">

      </div>

      <div class="flow_popbox">
        <div class="flow_pop1" v-if="popShow1">
          <img @click="submitBtn" style="width:10px;height:10px;" src="../../../assets/cloneBtn2.png" />
          <div class="pop1">
            <!-- <div v-if="tableData.shipments.length>1" class="receiver">
                        <div style="display:flex"><span class="title_pop">寄出时间:</span>{{tableData.shipments[1].create_time}}</div>
                        <div style="display:flex"><span class="title_pop">快递公司:</span>{{tableData.shipments[1].shipments_info.company}}</div>
                        <div style="display:flex"><span class="title_pop">快递单号:</span><span class="address_text_flow">{{tableData.shipments[1].shipments_info.order_sn}}</span></div>
                    </div> -->

            <div v-if="tableData.shipments.length>1" class="receiver">
              <div style="display:flex;margin-bottom:20px"><span class="title_pop">寄出时间:</span>{{tableData.shipments[1].create_time}}</div>
              <div style="display:flex;margin-bottom:20px"><span class="title_pop">快递公司:</span>{{tableData.shipments[1].shipments_info.company}}</div>
              <div style="display:flex;margin-bottom:20px"><span class="title_pop">快递单号:</span><span class="address_text_flow">{{tableData.shipments[1].shipments_info.order_sn}}</span></div>

            </div>
            <div v-if="tableData.shipments.length>1" style="display:flex;margin-bottom:20px"><span class="title_pop">收货状态:</span>{{tableData.shipments[1].receive_at?'已收货':'待收货'}}</div>
            <div style="display:flex;color: #707070FF"><span class="title_pop">收件人:</span>刘近</div>
            <div style="display:flex;color: #707070FF"><span class="title_pop">收件人电话:</span>15623988220</div>
            <div style="display:flex;color: #707070FF"><span class="title_pop">收件地址:</span><span class="address_text_flow">武汉市江岸区金桥大道特115号长江传媒大厦13楼</span></div>

            <!-- <div  v-if="tableData.shipments.length==1"> -->
            <div v-if="tableData.shipments.length==1">
              <div style="color:#FF5E5E;margin-bottom:20px">*请将收到的全部物料按要求填写完毕寄回。</div>

              <div class="formList_flow" style="margin-bottom:20px">
                <div class="offine_title_flow">快递公司:</div>
                <input v-model="dataForm.company.value" />
              </div>
              <div class="formList_flow" style="margin-bottom:20px">
                <div class="offine_title_flow">快递单号:</div>
                <input @focus='test' v-model="dataForm.order_sn.value" />
              </div>
              <div class="formList_flow" style="margin-bottom:20px">
                <div class="offine_title_flow">备注:</div>
                <textarea v-model="dataForm.message.value"></textarea>
              </div>
              <div class="submitBtn" @click="companyBtn">提交</div>
            </div>

          </div>

          <div class="pop2" v-if="popShow2">
            <div style="display:flex"><span class="title_pop">寄出时间::</span>2020-2-14 10:45</div>
            <div style="display:flex"><span class="title_pop">快递公司::</span>顺丰速运</div>
            <div style="display:flex"><span class="title_pop">物流单号::</span>45694894561</div>
            <div style="display:flex"><span class="title_pop">收件人:</span>张无忌</div>
            <div style="display:flex"><span class="title_pop">联系方式:</span>15975641874</div>
            <div style="display:flex"><span class="title_pop">收件地址:</span><span class="address_text_flow">湖北省武汉市洪山区民院路708号中南民族大学</span></div>
          </div>

          <div class="pop3" v-if="popShow2">
            <div style="display:flex"><span class="title_pop">快递单号：</span><span>4568849621462</span></div>
            <div style="display:flex"><span class="title_pop">收件人</span><span>张无忌</span></div>
            <div style="display:flex"><span class="title_pop">收件地址:</span><span>湖北省武汉市洪山区民院路708号中南民族大学</span></div>
          </div>
        </div>

        <div v-if="popShow3" class="shipper_box">
          <div class="order" v-if="tableData.shipments.length>0&&!tableData.shipments[0].receive_at" @click="getOrder">确认收货</div>
          <img @click="submitBtn" class="cloneBtn2" style="width:10px;height:10px;" src="../../../assets/cloneBtn2.png" />
          <div class="loginBtn1">
            <div style="text-align:right;width:100px;margin-right:30px">物流单号:</div>
            <div>{{dataList.LogisticCode}}</div>
          </div>
          <div class="loginBtn1">
            <div style="text-align:right;width:100px;margin-right:30px">收件人:</div>
            <div>{{tableData.shipments[0].shipments_info.name}}</div>
          </div>
          <div class="loginBtn1">
            <div style="text-align:right;width:100px;margin-right:30px">收件人地址:</div>
            <div>{{tableData.shipments[0].shipments_info.address}}</div>
          </div>
          <div class="steps1" style="color:#000" v-if="dataList.State==3">
            <div>
              <div style="margin-left:-10px" class="boxcolor3">收</div>
              <div class="linecolor1"></div>
            </div>
            <div class="neth">
              <div style="color:#00000080">[收货地址]{{dataList.Location}}</div>
              <!-- <div>{{endData.express.Traces[0].AcceptStation}}</div> -->
            </div>
          </div>
          <div class="steps1" v-for="(item,index) in dataList.Traces" v-bind:key="index">
            <div v-if="index!=0">
              <div class="boxcolor2"></div>
              <div class="linecolor1"></div>
            </div>
            <div v-else>
              <div class="boxcolor1"></div>
              <div class="linecolor1"></div>
            </div>
            <div :class="index==0&&item.Action!=3?'neth colorText':'neth'">
              <div v-if="item.Action==1">以揽件{{item.AcceptTime}}</div>
              <div v-if="item.Action==2||item.Action==201">运输中{{item.AcceptTime}}</div>
              <div v-if="item.Action==202">派送中{{item.AcceptTime}}</div>
              <div v-if="item.Action==211">待取件{{item.AcceptTime}}</div>
              <div v-if="item.Action==3">已签收{{item.AcceptTime}}</div>
              <div>{{item.AcceptStation}}</div>
            </div>
          </div>
          <div class="steps1" v-if="dataList.State==2">
            <div>
              <div :class="dataList.State==0?'boxcolor1':'boxcolor2'"></div>
              <div class="linecolor1"></div>
            </div>
            <!-- <div class="neth" :style="dataList.State==0?'color:#000':''">
                    <div>已发货{{tableData.update_time}}</div>
                    <div>等待揽收中</div>
                  </div> -->
          </div>
          <div class="steps1">
            <div>
              <div class="boxcolor2"></div>
            </div>
            <div class="neth">
              <div>已下单{{dataList.pay_at}}</div>

            </div>
          </div>
        </div>
        <!-- <div v-else>
                    <div class="auditInfo">物流信息:</div>
                <div class="cart">
                  <img src="../../../assets/cart.png"/>
                </div>
            </div> -->

      </div>
    </div>

    <!-- 提示框 -->
    <toast v-if="toastShow" :config="configData"></toast>
  </div>
</template>
<script type="text/javascript">
import * as apiCommon from "@/api/common";
import {
  getShipments_api,
  shipments_api,
  deliverShipments_api,
  getShipmentsList_api
} from "@/api/admin";
import toast from "@/components/toast";
// import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      dataForm: {
        company: {
          value: "",
          title: "快递公司",
          text: "请输入快递公司",
          color: "#DEB77A"
        },
        order_sn: {
          value: "",
          title: "快递单号",
          text: "请输入快递单号",
          color: "#DEB77A"
        },
        message: {
          value: "",
          title: "备注",
          text: "请输入备注",
          color: "#DEB77A"
        }
      },
      popShow: false,
      popShow1: false,
      popShow2: false,
      popShow3: false,
      companyShow: true,
      tableData: {
        info_state: "",
        shipments: [],
        info_remark: [],
        audit_remark: []
      },
      dataList: null,
      queryList: null,
      companyCode: "",
      toastShow: false,
      configData: {
        title: ""
      },
    };
  },
  components: {
    toast
  },
  async created() {
    document.title = "流程状态";
    this.queryList = this.$route.query;
    // if(this.queryList.audit_id){
    await this.getDetails(this.queryList.audit_id);
    // }
  },
  methods: {
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../../lador/service");
    },
    detailsBtn() {
      this.$router.push("../contract");
    },
    test() {
      let data = {
        LogisticCode: this.dataForm.order_sn
      };
      getShipmentsList_api(data).then(res => {
        if (res.code == 0) {
          this.companyCode = res.code;
        }
      });
    },
    logistics() {
      this.tableData.shipments[0].shipments_info.logistic = 0;
      getShipments_api(this.tableData.shipments[0].shipments_info).then(res => {
        console.log(res.data);
        if (res.code == 0) {
          this.dataList = res.data;
          if (this.dataList) {
            this.dataList.Traces.reverse();
          }
          console.log(this.dataList);
          this.$forceUpdate();
        }
      });
    },
    submitBtn() {
      console.log(1);
      this.popShow = false;
      this.popShow2 = false;
      this.popShow1 = false;
      this.popShow3 = false;
    },
    logistics1() {
      this.popShow = true;
      this.popShow3 = true;
      this.$forceUpdate();
    },
    lookAddress() {
      this.popShow = true;
      this.popShow1 = true;
    },
    lookBtn() {
      if (this.queryList.status) {
        this.$router.push("./index");
      } else {
        this.$router.push(`./index?id=${this.tableData.audit_id}`);
      }
    },
    getOrder() {
      this.tableData.shipments[0].shipments_info.logistic = 0;
      deliverShipments_api(this.tableData.shipments[0].shipments_id).then(
        res => {
          if (res.code == 0) {
            this.popShow = false;
            this.popShow3 = false;
            this.getDetails(this.queryList.audit_id);
          }
        }
      );
    },
    companyBtn() {
      if (!this.companyShow) {
        this.toastShow = true;
        this.configData.title = "请勿多次点击";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        return false;
      }
      this.companyShow = false;
      for (var i in this.dataForm) {
        if (!this.dataForm[i].value) {
          return false;
        }
      }
      let data = {
        audit_id: this.queryList.audit_id,
        audit_type: 1,
        shipments_info: {
          company: this.dataForm.company.value,
          order_sn: this.dataForm.order_sn.value,
          message: this.dataForm.message.value
        }
      };
      shipments_api(data).then(res => {
        this.companyShow = true
        if (res.code == 0) {
          this.popShow = false;
          this.popShow2 = false;
          this.popShow1 = false;
          this.popShow3 = false;
          location.reload();
        }
      });
    },
    async getDetails(id) {
      let res = await apiCommon.getShareDetail_api(id);
      if (res.code == 0) {
        console.log(res.data);
        this.tableData = res.data;
        this.$forceUpdate();
        if (this.tableData.shipments.length > 0) {
          this.logistics();
        }
      }
    }
  }
};
</script>
<style>
.receiver div {
  margin-bottom: 20px;
  color: #707070ff;
}
.time_text {
  font-size: 20px;
  color: #000;
  margin: 10px 0 20px 10px;
}
.pop3 {
  height: 776px;
  background: #ffffff;
  font-size: 16px;
  color: #707070;
  padding: 20px 20px 20px 50px;
}
.payClass {
  color: #4b93ff;
  font-size: 25px;
  margin-left: 10px;
}
.shipper_box {
  width: 700px;
  height: 800px;
  background-color: #fff;
  margin: 200px auto 0;
  font-size: 20px;
  color: #00000080;
  overflow-y: scroll;
  position: relative;
  padding: 50px;
  box-sizing: border-box;
}
.flow_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  text-align: right;
  margin: 25px 0;
}
.flow_contanter {
  padding: 0 38px;
  box-sizing: border-box;
}
.userBtn {
  float: right;
  text-align: center;
}
.userBtn img {
  width: 36px;
  height: 36px;
}
.userBtn div {
  font-size: 15px;
  color: #000;
}
.flow_box {
  width: 37px;
  height: 37px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 37px;
  color: #737373;
  opacity: 1;
  text-align: center;
  border: 2px solid #737373;
  border-radius: 50%;
}
.flow_content {
  margin-top: 100px;
}
.line_flow {
  width: 1px;
  border: 1px dashed #737373;
  margin: 0 18px;
  height: 150px;
}
.flow_list {
  display: flex;
  padding: 30px auto;
}
.flow_imgBtn {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: auto 0;
}
.flow_title {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(115, 115, 115, 0.5);
  margin: 0 10px;
}
.flow_text1 {
  margin-left: 10px;
  font-size: 20px;
  color: #4b93ff;
  margin-top: 15px;
}
.flow_popbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 30px;
}
.flow_pop1 {
  width: 600px;
  /* height:700px; */
  background-color: #fff;
  margin: 200px auto 0;
  /* text-align:right; */
  padding: 100px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.flow_pop1 img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.pop1 {
  font-size: 22px;
}
.pop1 > div {
  margin: 20px 60px;
  text-align: none;
}
.title_pop {
  width: 130px;
  display: inline-block;
  color: #707070ff;
}
.pop3 > div {
  margin-top: 20px;
}
.formList_flow {
  display: flex;
}
.offine_title_flow {
  width: 100px;
  font-size: 22px;
  color: #707070;
  margin: 0;
}
.pop1 .submitBtn {
  width: 160px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border: 1px solid #ff5e5e;
  color: #ff5e5e;
  margin: 20px auto 0;
}
.address_text_flow {
  width: 300px;
}
.pop2 > div {
  font-size: 26px;
  color: #707070;
  margin: 20px 0;
}
.pop2 {
  margin-left: 80px;
}
.selectColor {
  color: #ff5e5e;
  border: 1px solid #ff5e5e;
}
.selectLine {
  border: 1px dashed #ff5e5e;
}
@step-icon-size {
  font-size: 40px;
}
.title_text {
  color: #4b93ff;
  margin: 0px 0 0 20px !important;
  font-size: 24px;
}
.card {
  margin: 20px 0;
}
.success2 {
  width: 24px;
  height: 24px;
  margin: 10px 0 0 5px;
}
.messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle1 {
  text-align: right;
  margin-right: 20px;
}
.loginBtn1 {
  margin: 20px 0;
  display: flex;
  /* margin-left: 71px */
}
.loginBtn1 > div {
  /* display: flex; */
  /* margin-left: 77px; */
}
.loginBtn1 span {
  margin: 0 5px;
}
.comfigBtn {
  width: 124px;
  height: 40px;
  background-color: #4b93ff;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  margin-left: 79px;
}
.getAddress {
  display: flex;
  margin-left: 70px;
  margin-top: 30px;
}
.steps1 {
  /* height: 200px; */
  display: flex;
  margin-left: 60px;
}
.steps1 .colorText {
  color: #000000;
}
.steps1 .neth div {
  margin: 5px 0;
}
.neth {
  padding: 5px 0 20px 10px;
}
.linecolor {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px dashed #ff5e5e;
  margin-left: 18.5px;
}
.linecolor1 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.linecolor2 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.boxcolor {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  line-height: 37px;
  color: #ff5e5e;
  font-size: 22px;
}
.boxcolor1 {
  width: 13px;
  height: 13px;
  background: #ff5e5e;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor2 {
  width: 13px;
  height: 13px;
  background: #d1d1d1;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor3 {
  width: 44px;
  height: 44px;
  background: #fc6305;
  border-radius: 50%;
  opacity: 1;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
}
.cart {
  width: 120px;
  height: 120px;
  margin: 190px auto 0;
}
.cart img {
  width: 100%;
  height: 100%;
}
.auditInfo {
  margin: 50px 0 0 37px;
}
.content1 {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 40px;
  box-sizing: border-box;
  width: 100%;
}
.steps {
  height: 150px;
  padding-left: 61px;
}
.step_text {
  font-size: 22px;
  margin: 52px 0 0 54px;
}
.van-step--vertical {
  font-size: 22px;
  color: #000000;
}
.van-step--vertical {
  /* height: 200px; */
  line-height: 45px;
}
.title1 {
  color: #ff5e5e;
}
.van-step__icon--active,
.van-step__icon--finish,
.van-step__title--active,
.van-step__title--finish {
  color: #ff5e5e;
}
.submit {
  width: 160px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #4b93ff;
  opacity: 1;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  margin-left: 100px;
  margin-top: 20px;
}
.logistics {
  margin-left: 70px;
}
.cloneBtn2 {
  position: absolute;
  top: 20px;
  right: 20px;
}
.order {
  width: 141px;
  height: 51px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  opacity: 1;
  border-radius: 5px;
  text-align: center;
  line-height: 51px;
  font-size: 25px;
  color: #ff5e5e;
  margin-left: 70px;
}
</style>